import slugify from 'slugify'

const isDeployPreview = process.env.CONTEXT === 'deploy-preview'
const isBranchDeploy = process.env.CONTEXT === 'branch-deploy'
const isDev = process.env.CONTEXT === 'dev'
const isProduction = process.env.CONTEXT === 'production'

export const config = {
  app: {
    title: 'Insurance NTT DATA',
    description: 'Transforming Insurers into Tomorrow’s Ecosystems Leaders',
    author: '@nttdata',
    sitemapUrl: '/sitemap-index.xml',
    url: process.env.URL ?? '',
    apiAllowedOrigins: ['https://*.netlify.app', 'https://*.nttdata.com'],
    webhooksKeyEncrypt: process.env.FUNCTION_SECRET_KEY_ENCRYPT ?? '',
    secretKeyEncrypt: process.env.FUNCTION_SECRET_KEY_ENCRYPT ?? '',
    disableRobotsAndSitemap: process.env.DISABLE_ROBOTS_AND_SITEMAP ?? '',
    maintenacePage: process.env.MAINTENANCE_PAGE,
    defaultItemsPerPage: process.env.DEFAULT_NUMBER_ITEMS_PER_PAGE ?? 12,
    unsplashAccount: ''
  },
  cookies: {
    scriptUrl: `https://app.secureprivacy.ai/script/${process.env.GATSBY_COOKIESANDPRIVACY}.js`
  },
  env: {
    pathPrefix: process.env.PATH_PREFIX ?? '',
    runningJest: process.env.JEST_WORKER_ID,
    isNodeDevelopment: process.env.NODE_ENV === 'development',
    isTest: process.env.NODE_ENV === 'test',
    isPullRequest: process.env.PULL_REQUEST === 'true',
    isBuildingPages: process.env.gatsby_executing_command === 'build',
    isProduction,
    isDeployPreview,
    isBranchDeploy,
    isDev,
    context: process.env.CONTEXT,
    current: process.env.CONTEXT ?? process.env.NODE_ENV,
    url:
      isProduction || isDev
        ? process.env.URL
        : process.env.DEPLOY_PRIME_URL ?? process.env.URL
  },
  datocms: {
    apiToken: process.env.DATO_API_TOKEN ?? '',
    environment: getDatoCMSEnvironment(),
    cmaApiToken: process.env.DATOCMS_API_TOKEN ?? '',
    webhookUser: process.env.DATOCMS_WEBHOOK_USER ?? '',
    webhookPass: process.env.DATOCMS_WEBHOOK_PASS ?? '',
    webhookUrl: 'https://webhooks.datocms.com/kptXArhKCm/deploy-results'
  },
  sentry: {
    dsnFrontend: process.env.GATSBY_SENTRY_DSN ?? '',
    sampleRateFrontend: process.env.GATSBY_SENTRY_SAMPLERATE ?? '1',
    sampleRateBackend: process.env.SENTRY_BACKEND_SAMPLERATE ?? '1',
    dsnBackend: process.env.SENTRY_BACKEND_DSN,
    environment: process.env.GATSBY_SENTRY_ENV ?? 'production'
  },
  matomo: {
    url: process.env.GATSBY_MATOMO_URL ?? '',
    siteId: process.env.GATSBY_MATOMO_SITE_ID ?? ''
  },
  airtable: {
    baseId: process.env.AIRTABLE_BASE_ID ?? '',
    apiKey: process.env.AIRTABLE_API_KEY ?? ''
  },
  sendgrid: {
    from: 'global-insurance_ev@nttdata.com',
    fromName: 'Global Insurance NTT DATA',
    apiKey: process.env.SENDGRID_API_KEY ?? '',
    senderId: process.env.SENGRID_SENDER_ID ?? '',
    listIdNewsletter: process.env.SENGRID_NEWSLETTER_INSURANCE_LIST ?? '',
    linkUnsubscribe: process.env.SENGRID_UNSUBSCRIBE_GROUP_NEWSLETTER ?? '',
    templateIdSubscription: process.env.SENDGRID_TEMPLATEID_CONFIRMATION ?? '',
    templateIdGateContentSingle:
      process.env.SENDGRID_TEMPLATEID_REQUEST_PAPER ?? '',
    templateIdGateContentMultiple:
      process.env.SENDGRID_TEMPLATEID_REQUEST_PAPER_MULTIPLE ?? '',
    templateIdGateContentInsurtech:
      process.env.SENDGRID_TEMPLATEID_INSURTECH ?? '',
    insurtech: {
      defaultBackgroundColor: {
        backgroundColor: '#3B486F',
        textColor: '#FFFFFF'
      },
      papersBackgroundColors: [
        {
          backgroundColor: '#2E404D',
          textColor: '#FFFFFF'
        },
        {
          backgroundColor: '#FAFAFA',
          textColor: '#070F26'
        },
        {
          backgroundColor: '#F1F1F1',
          textColor: '#070F26'
        },
        {
          backgroundColor: '#E8E8E8',
          textColor: '#070F26'
        }
      ]
    }
  },
  recaptcha: {
    key: process.env.GATSBY_RECAPTCHA_KEY ?? '',
    secretKey: process.env.SITE_RECAPTCHA_SECRET ?? '',
    siteVerifyUrl: 'https://www.google.com/recaptcha/api/siteverify'
  }
}

function getDatoCMSEnvironment(): string {
  const deployPreviewEnvironment = `deploy-preview-${process.env.REVIEW_ID}`
  // Git HEAD env var is only available on build time
  const branchDeployEnvironment = process.env.HEAD
    ? slugify(`${process.env.HEAD}`)
    : slugify(`${process.env.DATO_ENVIRONMENT}`)
  const defaultEnvironment = process.env.DATO_ENVIRONMENT ?? ''

  if (isDeployPreview) {
    return deployPreviewEnvironment
  } else if (isBranchDeploy) {
    return branchDeployEnvironment
  } else {
    return defaultEnvironment
  }
}
