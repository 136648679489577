/* eslint-disable sonar/function-name */
import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, useContext, useMemo } from 'react'

import { resolvePctLinkUrl } from '../../internal/utils/resolve-pctlink-url'

type StaticQueryData = {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  }
  allDatoCmsPsGlobalSetting: {
    nodes: [DatoCmsPsGlobalSetting]
  }
}

type DatoCmsPsGlobalSetting = {
  codeId: string
  value: string
  valueMultiple: string
  settingType: string
  mediaFile: {
    url: string
  }
  externalVideo: {
    url: string
  }
  datetime: Date
  date: Date
  color: {
    hex: string
  }
  number: number
  floatingNumber: number
  boolean: boolean
  link: Queries.PctLinkFragment
  json: string
}

type RootElementProps = {
  readonly children: React.ReactNode
}
/*
 * We have customized the `render` and `rendered` functions from the `@testing-library/react` library.
 * The reason for this customization is that several of our components depend on a specific context to function correctly.
 * This context, defined in `src/components/root-element.tsx`, is responsible for loading global settings from the nodes of (`DatoCmsPsGlobalSetting`) that come from DatoCMS.
 * By wrapping our components in this context during testing, we ensure that they behave the same way as they would in the production environment of our application.
 */
export const RootElement = ({
  children
}: Readonly<RootElementProps>): React.ReactElement => {
  const data: StaticQueryData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allDatoCmsPsGlobalSetting(limit: 1000) {
        nodes {
          codeId
          help
          value
          valueMultiple
          settingType
          mediaFile {
            url
          }
          externalVideo {
            url
          }
          datetime
          date
          color {
            hex
          }
          number
          floatingNumber
          boolean
          link {
            ... on DatoCmsPctLink {
              ...PctLink
            }
          }
          json
        }
      }
    }
  `)
  const globalConfig: GlobalConfig = useMemo(() => {
    const settingTypeToValue: Record<
      string,
      (node: DatoCmsPsGlobalSetting) => string | number | JSX.Element | Date
    > = {
      value: (node: DatoCmsPsGlobalSetting) => node.value,
      value_multiple: (node: DatoCmsPsGlobalSetting) => node.valueMultiple,
      media: (node: DatoCmsPsGlobalSetting) => node.mediaFile.url,
      external_video: (node: DatoCmsPsGlobalSetting) => node.externalVideo.url,
      link: (node: DatoCmsPsGlobalSetting) => resolvePctLinkUrl(node.link),
      datetime: (node: DatoCmsPsGlobalSetting) => node.datetime,
      date: (node: DatoCmsPsGlobalSetting) => node.date,
      color: (node: DatoCmsPsGlobalSetting) => node.color.hex,
      number: (node: DatoCmsPsGlobalSetting) => node.number,
      floating_number: (node: DatoCmsPsGlobalSetting) => node.floatingNumber,
      json: (node: DatoCmsPsGlobalSetting) => node.json
    }
    const settings = data.allDatoCmsPsGlobalSetting.nodes.reduce<
      Record<string, string | number | Date | JSX.Element>
    >((acc, node: DatoCmsPsGlobalSetting) => {
      if (typeof settingTypeToValue[node.settingType] === 'function') {
        const value = settingTypeToValue[node.settingType](node)

        if (value !== undefined) {
          acc[node.codeId] = value
        }
      }

      return acc
    }, {})

    return {
      siteUrl: data.site?.siteMetadata.siteUrl,
      settings
    }
  }, [data])
  return (
    <SiteMetadataContext.Provider value={globalConfig}>
      <div>{children}</div>
    </SiteMetadataContext.Provider>
  )
}

export default RootElement

export const SiteMetadataContext = createContext<GlobalConfig | undefined>(
  undefined
)

export const useSiteMetadata = (): GlobalConfig => {
  const contextValue = useContext(SiteMetadataContext)
  if (contextValue === null || contextValue === undefined) {
    throw new Error(
      'useSiteMetadata hook was used outside of SiteMetadataContext provider'
    )
  }
  return contextValue
}
