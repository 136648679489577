import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import React from 'react'
import {
  type ShouldUpdateScrollArgs,
  type GatsbyBrowser,
  type WrapRootElementBrowserArgs
} from 'gatsby'

import RootElement from '@/components/root-element'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element
}: WrapRootElementBrowserArgs) => {
  return <RootElement>{element}</RootElement>
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  prevRouterProps
}: ShouldUpdateScrollArgs) => {
  /* Checking if the pathname and search are the same, if they are, then it returns false to avoid scrolling top */
  if (
    location &&
    prevRouterProps?.location &&
    location?.pathname === prevRouterProps?.location?.pathname &&
    location?.search !== prevRouterProps.location?.search
  ) {
    return false
  }

  return true
}
