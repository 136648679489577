exports.components = {
  "component---src-templates-dialog-dialog-tsx": () => import("./../../../src/templates/dialog/dialog.tsx" /* webpackChunkName: "component---src-templates-dialog-dialog-tsx" */),
  "component---src-templates-event-event-tsx": () => import("./../../../src/templates/event/event.tsx" /* webpackChunkName: "component---src-templates-event-event-tsx" */),
  "component---src-templates-form-newsletter-tsx": () => import("./../../../src/templates/form/newsletter.tsx" /* webpackChunkName: "component---src-templates-form-newsletter-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../../../src/templates/home/home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */),
  "component---src-templates-interview-interview-tsx": () => import("./../../../src/templates/interview/interview.tsx" /* webpackChunkName: "component---src-templates-interview-interview-tsx" */),
  "component---src-templates-list-authors-tsx": () => import("./../../../src/templates/list/authors.tsx" /* webpackChunkName: "component---src-templates-list-authors-tsx" */),
  "component---src-templates-list-dialogs-tsx": () => import("./../../../src/templates/list/dialogs.tsx" /* webpackChunkName: "component---src-templates-list-dialogs-tsx" */),
  "component---src-templates-list-episodes-tsx": () => import("./../../../src/templates/list/episodes.tsx" /* webpackChunkName: "component---src-templates-list-episodes-tsx" */),
  "component---src-templates-list-events-tsx": () => import("./../../../src/templates/list/events.tsx" /* webpackChunkName: "component---src-templates-list-events-tsx" */),
  "component---src-templates-list-interviews-tsx": () => import("./../../../src/templates/list/interviews.tsx" /* webpackChunkName: "component---src-templates-list-interviews-tsx" */),
  "component---src-templates-list-papers-tsx": () => import("./../../../src/templates/list/papers.tsx" /* webpackChunkName: "component---src-templates-list-papers-tsx" */),
  "component---src-templates-list-posts-tsx": () => import("./../../../src/templates/list/posts.tsx" /* webpackChunkName: "component---src-templates-list-posts-tsx" */),
  "component---src-templates-list-projects-tsx": () => import("./../../../src/templates/list/projects.tsx" /* webpackChunkName: "component---src-templates-list-projects-tsx" */),
  "component---src-templates-not-found-404-tsx": () => import("./../../../src/templates/not-found/404.tsx" /* webpackChunkName: "component---src-templates-not-found-404-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/page/page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-paper-paper-tsx": () => import("./../../../src/templates/paper/paper.tsx" /* webpackChunkName: "component---src-templates-paper-paper-tsx" */),
  "component---src-templates-person-person-tsx": () => import("./../../../src/templates/person/person.tsx" /* webpackChunkName: "component---src-templates-person-person-tsx" */),
  "component---src-templates-podcast-episode-tsx": () => import("./../../../src/templates/podcast/episode.tsx" /* webpackChunkName: "component---src-templates-podcast-episode-tsx" */),
  "component---src-templates-post-post-tsx": () => import("./../../../src/templates/post/post.tsx" /* webpackChunkName: "component---src-templates-post-post-tsx" */),
  "component---src-templates-project-project-tsx": () => import("./../../../src/templates/project/project.tsx" /* webpackChunkName: "component---src-templates-project-project-tsx" */),
  "component---src-templates-report-report-jsx": () => import("./../../../src/templates/report/report.jsx" /* webpackChunkName: "component---src-templates-report-report-jsx" */),
  "component---src-templates-tag-tag-tsx": () => import("./../../../src/templates/tag/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tag-tsx" */),
  "component---src-templates-tag-topic-tsx": () => import("./../../../src/templates/tag/topic.tsx" /* webpackChunkName: "component---src-templates-tag-topic-tsx" */),
  "component---src-templates-vision-vision-jsx": () => import("./../../../src/templates/vision/vision.jsx" /* webpackChunkName: "component---src-templates-vision-vision-jsx" */)
}

