const routes = Object.freeze({
  notFoundRoute: '/404',
  tagRoute: '/tag',
  topicRoute: '/topic',
  eventRoute: '/event',
  indexRoute: '',
  paperRoute: '/paper',
  postRoute: '/post',
  dialogRoute: '/dialog',
  interviewRoute: '/interview',
  pageRoute: '',
  projectRoute: '/success-stories',
  internalPageRoute: '',
  personRoute: '/profile',
  episodeRoute: '/podcast',
  reportRoute: '',
  visionRoute: '',
  testRoute: '/_test',
  dialogsRoute: '/thought-leadership/dialogs',
  papersRoute: '/thought-leadership/papers',
  postsRoute: '/thought-leadership/posts',
  interviewsRoute: '/thought-leadership/interviews',
  projectsRoute: '/success-stories',
  episodesRoute: '/thought-leadership/podcasts',
  eventsRoute: '/thought-leadership/events',
  apiTriggerSyncAirtableRoute: '/api/trigger-sync-airtable/',
  apiWebhookSyncAirtableRoute: '/api/webhook-sync-airtable/',
  apiFormRoute: '/.netlify/functions/form-gate-content',
  apiFormSaveRoute: '/.netlify/functions/form-gate-content-save-background',
  apiSubscriptionRoute: '/.netlify/functions/form-newsletter-subscription',
  apiSubscriptionSaveRoute:
    '/.netlify/functions/form-newsletter-subscription-save-background',
  apiSubscriptionConfirmationRoute: '/api/subscription-confirmation/',
  subscriptionConfirmationSuccessRoute: '/thank-you/',
  productionRoute: 'https://insurance.nttdata.com',
  datocmsRoute: 'https://insurance.admin.datocms.com',
  datocmsEditorTypesRoute:
    'https://insurance.admin.datocms.com/editor/item_types',
  podcastPlayerPrefix: 'https://share.transistor.fm/e/'
})

export default routes
