import isNil from 'lodash/isNil'

import { generateUrl } from '@/internal/utils/generate-url'

export function resolvePctLinkUrl(
  link: Queries.PctLinkFragment | null,
  absolute = false
): string {
  if (isNil(link)) return ''
  const { content, url, hash, linkMedia, linkType } = link

  if (linkType === 'url' && url !== null && url !== '') {
    return url
  }

  if (linkType === 'asset' && linkMedia?.url != null) {
    return linkMedia.url ?? ''
  }

  if (linkType === 'content' && !isNil(content)) {
    const { slug } = content
    return generateUrl(content.__typename, slug, absolute, hash ?? '')
  }

  return ''
}
